// Generated by Framer (9f2fb1c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import * as sharedStyle from "../css/E36Ev_q2s";
import * as sharedStyle1 from "../css/ro7OPezbn";

const serializationHash = "framer-li4Wy"

const variantClassNames = {lODcuFrPg: "framer-v-6lxek1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, Fky6KbiGf: link ?? props.Fky6KbiGf, NdvBvBElo: title ?? props.NdvBvBElo ?? "Team"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, NdvBvBElo, Fky6KbiGf, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "lODcuFrPg", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-6lxek1", className, classNames)} data-framer-name={"text"} layoutDependency={layoutDependency} layoutId={"lODcuFrPg"} ref={refBinding} style={{borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><motion.div className={"framer-ydla8r"} data-framer-name={"label"} layoutDependency={layoutDependency} layoutId={"WWdpVu9Rl"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-qr4xtq"} data-styles-preset={"E36Ev_q2s"}><Link href={Fky6KbiGf} motionChild nodeId={"CKoZdQwPB"} openInNewTab={false} scopeId={"YGWeSVkmm"} smoothScroll><motion.a className={"framer-styles-preset-1wicq5s"} data-styles-preset={"ro7OPezbn"}>Team</motion.a></Link></motion.p></React.Fragment>} className={"framer-fbmaz2"} data-framer-name={"View open roles"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"CKoZdQwPB"} text={NdvBvBElo} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-li4Wy.framer-1sh8oqh, .framer-li4Wy .framer-1sh8oqh { display: block; }", ".framer-li4Wy.framer-6lxek1 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 9px 10px 9px 10px; position: relative; width: min-content; }", ".framer-li4Wy .framer-ydla8r { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 14px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-li4Wy .framer-fbmaz2 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-li4Wy.framer-6lxek1, .framer-li4Wy .framer-ydla8r { gap: 0px; } .framer-li4Wy.framer-6lxek1 > *, .framer-li4Wy .framer-ydla8r > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-li4Wy.framer-6lxek1 > :first-child, .framer-li4Wy .framer-ydla8r > :first-child { margin-left: 0px; } .framer-li4Wy.framer-6lxek1 > :last-child, .framer-li4Wy .framer-ydla8r > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 56
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"NdvBvBElo":"title","Fky6KbiGf":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerYGWeSVkmm: React.ComponentType<Props> = withCSS(Component, css, "framer-li4Wy") as typeof Component;
export default FramerYGWeSVkmm;

FramerYGWeSVkmm.displayName = "nav link";

FramerYGWeSVkmm.defaultProps = {height: 32, width: 56};

addPropertyControls(FramerYGWeSVkmm, {NdvBvBElo: {defaultValue: "Team", displayTextArea: false, title: "Title", type: ControlType.String}, Fky6KbiGf: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerYGWeSVkmm, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})